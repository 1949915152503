<template>
  <div>
    <b-card>
      <div header="test" header-bg-variant="success">
        <b-button @click="telecharger">
          Manifeste n° {{ manifeste.numero_manif }} (
          {{ manifeste.status == "0" ? "Non receptionné" : "Receptionné" }} )
        </b-button>
      </div>
    </b-card>
    <div>
      <div class="d-flex flex justify-space-between mb-2">
        <div class="gauche ml-2" id="logo">
          <img class="logo" :src="entete.logo" />
        </div>
        <div class="ml-auto mr-2">
          <div>
            <h2 class="font-weight-black">{{ entete.name }}</h2>
          </div>
          <div>RCCM : {{ entete.rccm }}</div>
          <div>{{ entete.address }} - {{ entete.telephone }}</div>
        </div>
      </div>

      <b-row class="mb-5">
        <b-table-simple responsive>
          <b-thead>
            <b-th>Société</b-th>
            <b-th>Association</b-th>
            <b-th>Compte</b-th>
            <b-th>CHARGEUR</b-th>
            <b-th>NAVIRE</b-th>
            <b-th>DATE D'ARRIVEE</b-th>
            <b-th>NOMBRE DE VOITURE</b-th>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td>{{ societe.name }}</b-td>
              <b-td>{{ association.name }}</b-td>
              <b-td>{{ compte ? compte.libelle : "------" }}</b-td>
              <b-td>{{ chargeur ? chargeur.name : "-----" }}</b-td>
              <b-td>{{ navire ? navire.name : "-----" }}</b-td>
              <b-td>{{ manifeste.date_arrivee }}</b-td>
              <b-td
                >{{ nbr_voitures_vendues }} Vendus /
                {{ manifeste.nbr_vehicules }}
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-row>

      <b-row class="mb-5">
        <b-table-simple responsive>
          <b-thead>
            <!-- <b-th class="font-weight-black">N°</b-th> -->
            <b-th>MARQUE / CHASSIS / COULEUR</b-th>
            <b-th>ANNEE</b-th>
            <b-th>ACHAT</b-th>
            <b-th>TRANSPORT</b-th>
            <b-th>CI</b-th>
            <b-th>FRAIS</b-th>
            <b-th>TOTAL ACHATS</b-th>
            <b-th>DEPENSES</b-th>
            <b-th>DEMARCHEUR</b-th>
            <b-th>PRIX DE VENTE FINAL</b-th>
            <b-th>BENEFICE</b-th>
            <b-th>STATUT</b-th>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(item, index) in vehicules" :key="index">
              <!-- <b-td>{{ index + 1 }}</b-td> -->
              <b-td>
                {{ item.marque }} / {{ item.numero_chassis }} /
                {{ item.couleur }}
              </b-td>
              <b-td>{{ item.annee }}</b-td>
              <b-td>{{ $thousandSeparator(item.prix_achat) }}</b-td>
              <b-td>{{ $thousandSeparator(item.prix_transport) }}</b-td>
              <b-td>{{ $thousandSeparator(item.prix_carte_imp) }}</b-td>
              <b-td>{{ $thousandSeparator(item.frais) }}</b-td>
              <b-td>{{ $thousandSeparator(item.total_achat) }}</b-td>
              <b-td>
                {{
                  item.total_dep > 0
                    ? $thousandSeparator(item.total_dep)
                    : "-----"
                }}
              </b-td>
              <b-td>
                {{
                  item.total_dem > 0
                    ? $thousandSeparator(item.total_dem)
                    : "-----"
                }}
              </b-td>
              <b-td>
                {{
                  item.prix_vente
                    ? $thousandSeparator(item.prix_vente)
                    : "-----"
                }}
              </b-td>
              <b-td>{{ $thousandSeparator(item.benefice) }}</b-td>
              <b-td>{{ item.statut }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-row>

      <b-row>
        <b-table-simple>
          <b-thead>
            <b-th>SYNTHESE DES PRIX</b-th>
            <b-th>MONTANT</b-th>
            <b-th>CONVERSION</b-th>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-th>ACHAT</b-th>
              <b-td
                >{{ $thousandSeparator(manifeste.prix_achat) }}
                <span color="primary" small pill>
                  {{ manifeste.dev_achat }}
                </span>
              </b-td>
              <b-td
                >{{ $thousandSeparator(manifeste.conv_achat) }}
                <span color="primary" small pill>
                  {{ manifeste.dev_conversion }}</span
                >
              </b-td>
            </b-tr>
            <b-tr>
              <b-th>TRANSPORT</b-th>
              <b-td
                >{{ $thousandSeparator(manifeste.prix_transport) }}
                <span color="primary" small pill>
                  {{ manifeste.dev_transport }}</span
                >
              </b-td>
              <b-td
                >{{ $thousandSeparator(manifeste.conv_transport) }}
                <span color="primary"> {{ manifeste.dev_conversion }}</span>
              </b-td>
            </b-tr>
            <b-tr>
              <b-th>CI</b-th>
              <b-td
                >{{ $thousandSeparator(manifeste.prix_carte_imp) }}
                <span color="primary">
                  {{ manifeste.dev_carte_imp }}
                </span>
              </b-td>
              <b-td
                >{{ $thousandSeparator(manifeste.conv_carte_imp) }}
                <span color="primary">{{ manifeste.dev_conversion }}</span>
              </b-td>
            </b-tr>
            <b-tr>
              <b-th>FRAIS</b-th>
              <b-td>
                {{ $thousandSeparator(manifeste.frais) }}
                <span color="primary">
                  {{ manifeste.dev_frais }}
                </span>
              </b-td>
              <b-td
                >{{ $thousandSeparator(manifeste.conv_frais) }}
                <span color="primary">
                  {{ manifeste.dev_conversion }}
                </span>
              </b-td>
            </b-tr>
            <b-tr>
              <b-th>TOTAL ACHATS</b-th>
              <b-td
                >{{ $thousandSeparator(totalAchats) }}
                <span color="primary"> {{ manifeste.dev_conversion }}</span>
              </b-td>
              <b-td></b-td>
            </b-tr>
            <b-tr>
              <b-th>TOTAL DES DEPENSES</b-th>
              <b-td>
                {{ $thousandSeparator(manifeste.total_depenses) }}
                <span color="primary"> {{ manifeste.dev_conversion }}</span>
              </b-td>
              <b-td></b-td>
            </b-tr>
            <b-tr>
              <b-th>PRIX DE REVIENT TOTAL</b-th>
              <b-td
                >{{
                  manifeste.prix_revient
                    ? $thousandSeparator(
                        manifeste.prix_revient - manifeste.total_demarcheurs
                      )
                    : 0
                }}
                <span color="primary">
                  {{ manifeste.dev_conversion }}
                </span>
              </b-td>
              <b-td></b-td>
            </b-tr>
            <b-tr>
              <b-th>PRIX DE VENTE TOTAL</b-th>
              <b-td>
                {{ $thousandSeparator(totalPrixVente) }}
                <span> {{ manifeste.dev_conversion }}</span>
              </b-td>
              <b-td></b-td>
            </b-tr>
            <b-tr>
              <b-th> TOTAL DES BENEFICES </b-th>
              <b-td>
                {{ $thousandSeparator(checkManifesteBenefice) }}
                <span>{{ manifeste.dev_conversion }}</span>
              </b-td>
              <b-td></b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-row>

      <div style="bottom: 0; font-size: 10px; opacity: 0.5">
        Edité par FlashCar
      </div>
    </div>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BForm,
  BModal,
  BButton,
  BIconPlus,
  BCardText,
  BFormInput,
  BFormGroup,
  BPagination,
  BFormSelect,
  BInputGroup,
  BFormCheckbox,
  BFormDatepicker,
  BIconCircleFill,
  BIconBuilding,
  BIconLayers,
  BIconOption,
  BIconFile,
  BTooltip,
  BIconCheckCircleFill,
  BIconPrinterFill,
  BIconCalendarEvent,
  BInputGroupAppend,
  BFormSelectOption,
  BIconFileEarmarkArrowDownFill,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
} from "bootstrap-vue";

export default {
  components: {
    BCardCode,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BIconPlus,
    BRow,
    BCol,
    BCard,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BCardText,
    BTable,
    BTooltip,
    BFormDatepicker,
    BIconCircleFill,
    BIconBuilding,
    BIconLayers,
    BIconOption,
    BIconFile,
    BIconPrinterFill,
    BIconCheckCircleFill,
    BIconCalendarEvent,
    BFormSelectOption,
    BIconFileEarmarkArrowDownFill,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
  },
  data: () => ({
    manifesteInfos: [],
    vehicules: [],
    societe: {},
    association: {},
    compte: {},
    chargeur: {},
    navire: {},
    manifeste: [],
    idManifeste: "",
    nbr_voitures_vendues: 0,

    fournisseurs: [],
    societe: [],
    navires: [],
    chargeurs: [],
    entete: {},
  }),
  mounted() {
    this.fournisseursData();
    this.chargeursData();
    this.naviresData();
    this.enteteData();
    this.manifesteData();
  },
  computed: {
    checkManifesteBenefice() {
      let b = 0;
      this.vehicules.forEach((element) => {
        b += element.benefice;
      });

      return b;
    },

    totalPrixVente() {
      let total_dep = 0;
      if (this.vehicules) {
        this.vehicules.forEach((vehicule) => {
          total_dep += vehicule.prix_vente;
        });
      }
      return total_dep;
    },

    totalAchats() {
      let total_achat = 0;
      if (this.vehicules) {
        this.vehicules.forEach((vehicule) => {
          total_achat += vehicule.total_achat;
        });
      }
      return total_achat;
    },
  },
  methods: {
    telecharger() {
      window.print();
      window.close();
    },

    enteteData() {
      this.$http.get("/societes").then((response) => {
        this.societe = response.data.data[0];
        this.entete = this.societe ? this.societe : {};
      });
    },

    // get the manifest
    async manifesteData() {
      let id = this.$router.currentRoute.params.id;
      await this.$http.get("/manifestes/" + id).then((response) => {
        this.manifeste = response.data.data;
        this.navire = this.manifeste.navire;
        this.societe = this.manifeste.societe;
        this.association = this.manifeste.association;
        this.compte = this.manifeste.compte;
        this.chargeur = this.manifeste.chargeur;

        this.vehiculesData();
      });
    },

    //RECUPERER LA LISTE DES VEHICULES
    async vehiculesData() {
      this.manifeste.total_depenses = 0;
      this.manifeste.total_demarcheurs = 0;

      let response = await this.$http.get("/vehicules").then((response) => {
        // this.vehicules = response.data.data
        this.vehicules = response.data.data.filter(
          (v) => v.manifeste_id == this.manifeste.id
        );

        this.vehicules.forEach((element) => {
          let total_dep = 0;
          let total_dem = 0;
          if (element.maintenances) {
            element.maintenances.forEach((mtnace) => {
              if (mtnace.type != "Paiement démarcheur") {
                total_dep += mtnace.prix;
              } else {
                total_dem += mtnace.prix;
              }
            });
          }
          element.total_dep = total_dep;
          element.total_dem = total_dem;
          element.total_achat =
            element.conv_achat +
            element.conv_transport +
            element.conv_frais +
            element.conv_carte_imp;

          //Ajouter les depenses du vehicule aux depenses du manifeste
          this.manifeste.total_depenses += element.total_dep;
          this.manifeste.total_demarcheurs += element.total_dem;

          if (
            element.statut == "vendu" ||
            element.statut == "en cours de vente" ||
            element.statut == "livré"
          )
            this.nbr_voitures_vendues++;
        });
      });

      this.vehicules.forEach((element) => {
        element.prix_vente -= element.total_dem;
      });
    },

    //     get all Fournisseurs
    async fournisseursData() {
      let response = await this.$http.get("/associes").then((response) => {
        this.fournisseurs = response.data.data;
      });
    },

    //     get all Chargeurs
    async chargeursData() {
      let response = await this.$http.get("/chargeurs").then((response) => {
        this.chargeurs = response.data.data;
      });
    },

    //     get all Navires
    async naviresData() {
      let response = await this.$http.get("/navires").then((response) => {
        this.navires = response.data.data;
      });
    },
  },
};
</script>

<style scoped>
@page {
  size: landscape;
}

.img {
  width: 70% !important;
}

.logo {
  width: 50%;
  max-width: 150px;
  height: auto;
}

/* span .color { */
/* background-color: red; */
/* } */
</style>
